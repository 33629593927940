<template>
    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
       
        <b-card
          title="Add Inventory Area User">
          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-1"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>
          
          <b-form @submit="formSubmit">
            
            <b-row>
              
              <b-col md="12">
    
                <b-row>
                    <b-col md="12">
                        <b-form-group
                        label="Area"
                        class="required legend_top_space_mobile"
                        >
                        <v-select
                              v-model="form.area"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="name"
                              :options="areas"
                              placeholder="Select"
                              @input="changeArea()"
                              :clearable="false"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="fsmTable table-responsive" style="margin-bottom: 0px !important;">
                  <table class="table">
                    <thead>
                        <tr>
                            <th scope="col" style="width: 23%">Project Site <span class="text-danger">*</span></th>
                            <th scope="col" style="width: 23%">Area In-Charge <span class="text-danger">*</span></th>
                            <th scope="col" style="width: 23%">Site In-Charge <span class="text-danger">*</span></th>
                            <th scope="col" style="width: 23%">Budget <span class="text-danger">*</span></th>
                            <th scope="col" style="width: 8%"></th>
                        </tr>
                    </thead>
                    <tbody>

                      <tr v-for="(item, index) in form.items" :key="index">
                        <td>
                          <v-select
                              v-model="item.site"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              label="site_name"
                              :options="sites"
                              placeholder="Select Project Site"
                              :appendToBody=true
                              :selectable="site => !site.disabled"
                              @input="updateValues(); getUsers(index);"
                            />
                        </td>
                        <td>
                          <v-select
                            v-model="item.user"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="full_name"
                            :options="item.users"
                            placeholder="Select Area In-Charge"
                            :appendToBody=true
                          />
                        </td>
                        <td>
                          <v-select
                            v-model="item.supervisor"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="full_name"
                            :options="item.supervisors"
                            placeholder="Select Site In-Charge"
                            :appendToBody=true
                          />
                        </td>

                         <td>
                          <b-form-input placeholder="$0 to $100000" v-model="item.budget" autocomplete="off" @keypress="isNumber($event)"/>
                        </td>
                        <!-- <td>
                          <b-form-input placeholder="$0 to $100000" v-model="item.budget" autocomplete="off" @keypress="isNumber($event)"/>
                        </td> -->

                        <td>
                          <b-button class="float-right" variant="danger" @click="removeRow(index);">-</b-button>
                        </td>
                      </tr>


                    </tbody>
                  </table>
                </div>

                <b-row>
                  <b-col md="12" class="text-center">
                      <hr>
                      <b-button class="mt-1 under_construct_mobile_add_more_btn" variant="primary" @click="addRow" :disabled="form.items.length == sites.length">Add More</b-button>
                  </b-col>
                </b-row>
                  

                
                
                <b-row class="mt-2">
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="warning"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="goBack()"
                    >
                      Cancel
                    </b-button>
                  </b-col>
                </b-row>
    
    
              </b-col>
            </b-row>
    
          </b-form>
        </b-card>
        </b-col>
      </b-row>
    
      
    </div>
    </template>
    
    <script>
    
    import {
      BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import vSelect from 'vue-select'
    
    export default {
      components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BMedia,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BFormTimepicker,
        VueTimepicker,
        BBreadcrumb,
        VueGoogleAutocomplete,
        vSelect
      },
      directives: {
        Ripple,
      },
      data() {
        return {
          error_message:null,
          showDismissibleAlert:false,
          sites:[],
          // users:[],
          // supervisors:[],
          areas:[],
          form :{
            area: null,
            items : [
              {
                site:null,
                user:null,
                supervisor:null,
                budget:null,
                users:[],
                supervisors:[],
              }
            ]
          },
          existing_sites: []
        }
      },
      methods : {
        isNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
    
        formSubmit(e){
          e.preventDefault();
    
          return this.$store.dispatch(POST_API, {
               data:{
                 items:this.form
               },
               api: '/api/add-inventory-area-user'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Record Added Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name: 'inventory-area-user', params: { area_id: this.$route.params.area_id } })
                    });
                    
                }
            });
          
        },
    
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Inventory'
          },{
            to:null,
            text: 'Settings'
          },{
            to:{name:'inventory-area'},
            text: 'Area Management',
          },{
            to:{name:'inventory-area-user',params:{area_id:this.$route.params.area_id}},
            text: 'Inventory Area User',
          }, {
            to: null,
            text: 'Add Inventory Area User',
          }];
          return item;
        },
        goBack(){
          this.$router.push({ name: 'inventory-area-user', params: { area_id: this.$route.params.area_id } })
        },
        
        getUsers(i) {
          return this.$store.dispatch(POST_API, {
            data: {
              site: this.form.items[i].site
            },
            api: '/api/all-inventory-area-users'
          })
            .then(() => {
              this.form.items[i].user = null;
              this.form.items[i].supervisor = null;

              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.form.items[i].users = this.$store.getters.getResults.data.om;
                this.form.items[i].supervisors = this.$store.getters.getResults.data.supervisors;
              }
            });
        },
        getInventoryArea() {
          return this.$store.dispatch(POST_API, {
            data: {
            },
            api: '/api/all-inventory-areas'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.areas = this.$store.getters.getResults.data;

                return this.areas;
              }
            });
        },
        changeArea(){
          
          this.sites = [];

          this.getProjectSites();

          this.form.items = [{
            site: null,
            user: null,
            supervisor: null,
            budget: null,
            users: [],
            supervisors: []
          }]
        },
        getProjectSites() {

          return this.$store.dispatch(POST_API, {
            data: {
              area_id:this.form.area != null ? this.form.area._id : null
            },
            api: '/api/all-inventory-areas-sites-by-area'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.sites = this.$store.getters.getResults.data;
                
                for(var i=0;i < this.sites.length;i++){
                  this.sites[i].disabled = false;
                  if( this.existing_sites.includes(this.sites[i]._id) ){
                    this.sites[i].disabled = true;
                  }
                }
                return this.sites;
              }
            });

        },
        async setParamsValue(){

          await this.getInventoryArea();

           if (this.$route.params.area_id != '') {
            var areaData = this.areas.filter(item => { return item._id == this.$route.params.area_id });
            this.form.area = areaData.length > 0 ? areaData[0] : null;
            this.getProjectSites();
          }


        },
        addRow() {
          this.form.items.push({
            site: null,
            user: null,
            supervisor: null,
            budget: null,
            users: [],
            supervisors: []
          })
        },

        removeRow(index) {
          this.form.items.splice(index, 1);
          if (this.form.items.length == 0) {
            this.addRow();
          }

          this.updateValues();
        },
        updateValues(){

          var selectedSite = [];

          this.form.items.forEach(item => {
              if (item.site != null) { 
                selectedSite.push(item.site._id) 
              }
          })

          for (var i = 0; i < this.sites.length; i++) {
            
            if(selectedSite.indexOf(this.sites[i]._id.toString()) >=0){

              this.sites[i].disabled = true;
            }
            else if( this.existing_sites.includes(this.sites[i]._id) ){
              this.sites[i].disabled = true;
            }
            else{
              this.sites[i].disabled = false;
            }
          }



        },

        getExistingSites(){
            return this.$store.dispatch(POST_API, {
                data: {},
                api: '/api/get-existing-sites'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                } else {
                    this.existing_sites = this.$store.getters.getResults.data;
                }
            });
        },
        
      },
      mounted(){
        // this.getUsers();
        //this.getInventoryArea();
        this.setParamsValue();
        this.getExistingSites();
      }
    }
    </script>

    <style lang="scss">
      @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    